import React, { createContext, useRef } from "react";

export const FormContext = createContext();
export const FormProvider = ({ children }) => {
  const childFormRef = useRef(null);

  return (
    <FormContext.Provider value={{ childFormRef }}>
      {children}
    </FormContext.Provider>
  );
};
