import React from "react";
import "../../style/home/footer.scss";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { Divider } from "antd";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Footers() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const naviagte = useNavigate();
  const socialIcons = [
    <Link
      style={{ color: "black" }}
      to="https://www.instagram.com/edgepages?igsh=dnd2YzkzbGE5Z29r"
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      <FaInstagram key="instagram" size={24} style={{ cursor: "pointer" }} />
    </Link>,
    <Link
      style={{ color: "black" }}
      to="https://x.com/edge_bepresent?s=21&t=LcYEe94LYXdsPanXaSuI5g"
      target="_blank"
      rel="noopener noreferrer"
    >
      {" "}
      <FaTwitter key="twitter" size={24} style={{ cursor: "pointer" }} />
    </Link>,
  ];

  return (
    <>
      <Divider />
      <div className="Footers">
        <Typography
          sx={{
            fontSize: {
              xs: 20,
              sm: 30,
            },
            fontWeight: "bold",
          }}
        >
          {t("home.welcome")}
        </Typography>

        <ul className="footer__nav">
          <li className="footer__link" onClick={() => naviagte("/aboutUs")}>
            {t("aboutus")}
          </li>
          <li
            onClick={() => naviagte("/privacyPolicy")}
            className="footer__link"
          >
            {t("privacypolicy")}
          </li>
          {pathname === "/" && (
            <>
              <li
                onClick={() => naviagte("/pricingTable")}
                className="footer__link"
              >
                {t("subscriptions")}
              </li>
              <li
                onClick={() => naviagte("/calendar")}
                className="footer__link"
              >
                {t("calendar")}
              </li>

              <li
                onClick={() => naviagte("/businessWall")}
                className="footer__link"
              >
                {t("businesswall")}
              </li>
            </>
          )}
          <li onClick={() => naviagte("/contactUs")} className="footer__link">
            {t("contactus")}
          </li>
          {/* <li
              onClick={() => naviagte("/categories")}
              className="footer__link"
            >
              Categories
            </li> */}
        </ul>

        <Divider />

        <div className="inner-icons">{socialIcons.map((icon) => icon)}</div>
      </div>
    </>
  );
}
