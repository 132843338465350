import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { UserProvider } from "./context/UserContext";
import { FormProvider } from "./context/formRefContext";
import "./i18n";
import { SubscriptionProvider } from "./context/subscriptionContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FormProvider>
      <UserProvider>
        <SubscriptionProvider>
          <App />
        </SubscriptionProvider>
      </UserProvider>
    </FormProvider>
  </React.StrictMode>
);
