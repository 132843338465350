import React, { createContext, useState, useEffect, useContext } from "react";
import { env } from "../utils/env";
import { UserContext } from "./UserContext";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [subscription, setSubscription] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const fetchSubscription = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${env.API_URL}/payment/get-subscription/${user?.plan?.SubscriptionReference}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          setLoading(false);
          const data = await response.json();
          setSubscription(data.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (user?.plan?.SubscriptionReference) fetchSubscription();
  }, [user?.plan?.SubscriptionReference]);

  return (
    <SubscriptionContext.Provider value={{ loading, subscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
