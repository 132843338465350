import React from "react";
import ReactLoading from "react-loading";

export default function Loading({ height = "100vh", style = {} }) {
  return (
    <>
      <div
        className="globalAlignItemsCenter globalJustifyContentCenter globalDisplay globalFullPageHeight globalFullWidth"
        style={{
          margin: "auto",
          height: height,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        <ReactLoading
          type={"spokes"}
          color={"blue"}
          height={"5%"}
          width={"5%"}
        />
      </div>
    </>
  );
}
